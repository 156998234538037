<template>
<footer>
    <div class="footer-top">
        <div v-if="mob" class="adits-mob-menu">
            <img fetchpriority = "low" alt="adits" src="../assets/home/adits-logo-white.svg">
        </div>
        <div class="footer-menu">
            <router-link to="/adits" class="cursor-hover-effect">ADITS</router-link>
            <router-link to="/aboutUs" class="cursor-hover-effect">ÜBER UNS</router-link>
            <router-link to="/team" class="cursor-hover-effect">TEAM</router-link>
            <router-link to="/dienstleistungen/0" class="cursor-hover-effect">LEISTUNGEN</router-link>
            <router-link to="/projekte" class="cursor-hover-effect">PROJEKTE</router-link>
            <router-link to="/contact" class="cursor-hover-effect">KONTAKT</router-link>
            <router-link to="/jobs" class="footer-menu-jobs  cursor-hover-effect">JOBS</router-link>
        </div>
        <div class="footer-top-right">
            <div class="footer-partner google-parner cursor-hover-effect">
                <div class="footer-partner-img">
                    <img fetchpriority = "low" alt="google" src="../assets/home/google-partner.svg">
                </div>
                <p>Official Google Partner</p>
            </div>
            <div class="footer-partner meta-partner cursor-hover-effect">
                <div class="footer-partner-img">
                    <img fetchpriority = "low" alt="meta" src="../assets/home/meta-partner.svg">
                </div>
                <p>Official Meta Partner</p>
            </div>
            <div class="social-medias">
                <p>Folgen sie uns</p><br><br>
                <a class="cursor-hover-effect" href="https://www.linkedin.com/company/adits-gmbh/about/" target="_blank">ln</a>
                <a class="cursor-hover-effect" href="https://www.instagram.com/adits.ch/" target="_blank">in</a>
                <a class="cursor-hover-effect" href="https://www.facebook.com/aditsgmbh/" target="_blank">fb</a>

            </div>
        </div>

    </div>
    <div class="footer-bottom">

        <p>&copy;{{year}} Adits GmbH</p>
        <div class="footer-bottom-list">
            <router-link to="/impressum" class="cursor-hover-effect">Impressum</router-link>
            <router-link to="/datenschutz" class="cursor-hover-effect">Datenschutz</router-link>
        </div>
    </div>
</footer>
</template>

<script>
export default {
    data() {
        return {
            year: new Date().getFullYear(),
            mob: false
        }
    },
    methods: {
        selectCursorHoverElements() {
            let innerCursor = document.querySelector(".cursor");
            let links = Array.from(document.querySelectorAll(".cursor-hover-effect"));

            links.forEach((link) => {
                link.addEventListener("mouseover", () => {
                    innerCursor.classList.add("grow");
                });
                link.addEventListener("mouseleave", () => {
                    innerCursor.classList.remove("grow");
                });
            });
        },
        windowResize() {
            if (window.innerWidth <= 480) {
                this.mob = true
            } else {
                this.mob = false
            }
        },
    },
    mounted() {
        this.windowResize()
        window.addEventListener("resize", this.windowResize);

        this.$nextTick(() => {
            this.selectCursorHoverElements();
        });
    },
    unmounted() {
        window.removeEventListener("resize", this.windowResize);
    },
}
</script>

<style scoped>
footer {
    color: white;
    background-color: #090909;
}

.footer-top {
    display: flex;
    padding: 5em 9em;
    align-items: flex-end;
    justify-content: space-between;
}

.footer-menu {
    display: flex;
    flex-direction: column;
}

.footer-menu a {
    color: white;
    font-family: "HKGrotesk-Light";
    padding: 0.6em 0;
    font-size: 1rem;
    letter-spacing: 2px;
}

.footer-menu a:first-child {
    border: 1px solid transparent;
    border-style: solid none;
    border-bottom-color: white;
}

.footer-menu-blog {
    color: #ED4815 !important;
}

.footer-menu-jobs {
    color: #4C4EB2 !important;
    color: #8c8de7 !important;
}

.footer-menu-blog:hover {
    border-bottom-color: #ED4815 !important;
}

.footer-menu-jobs:hover {
    border-bottom-color: #4C4EB2 !important;
}

.footer-top-right {
    display: flex;
    justify-content: flex-end;
}

.footer-partner {
    margin-right: 4.4em;
    text-align: center;
}

.footer-partner p {
    font-size: 0.9rem;
    margin-top: 1em;
}

.footer-partner {
    width: 10.6em;
    position: relative;
}

.footer-partner-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9D9D9;
    transition: 0.5s ease;
}

.footer-partner-img:hover {
    background-color: #ED4815;
}

.footer-partner-img img {
    width: 68%;
}

.footer-partner-img:hover img {
    filter: brightness(0) invert(1)
}

.social-medias {
    text-align: right;
}

.social-medias p,
.social-medias a {
    font-size: 1.5rem;
}

.social-medias a {
    margin-right: 0.625em;
    transition: 0.5s ease;
}

.social-medias a:hover {
    color: #ED481B;
}

.social-medias a:last-child {
    margin: 0;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    padding: 2.5em 6.875em;
    border: 3px solid transparent;
    border-style: solid none;
    border-top-color: white;
}

.footer-bottom p {
    word-spacing: 8px;
    font-family: "HKGrotesk-Light";
    font-size: 1rem;
}

.footer-bottom-list {
    display: flex;
    flex-wrap: wrap;
}

.footer-bottom-list a {
    margin-right: 3.125em;
    font-size: 1rem;
    letter-spacing: 2px;
    font-family: "HKGrotesk-Light";
    color: white;
    transition: color 0.3s ease;
}

.footer-bottom-list a:last-child {
    margin-right: 0;
}

.footer-bottom-list a:hover {
    color: #ED4815;
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {

    .footer-menu a,
    .footer-partner p,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 1.2rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 1.7rem;
    }

    .footer-partner {
        width: 13em;
    }
}

/* 2304-1470 */
@media (min-width: 2304px) {

    .footer-menu a,
    .footer-partner p,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 1.4rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 2rem;
    }

    .footer-partner {
        width: 15em;
    }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
    .footer-partner {
        width: 16em;
    }
}

/*2573-1206*/
@media(min-width: 2573px) {
    .footer-partner {
        width: 17em;
    }
}

/* 2880-1670 */
@media(min-width: 2880px) {

    .footer-menu a,
    .footer-partner p,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 1.6rem;
    }

    .footer-partner {
        width: 18em;
    }

    .social-medias p,
    .social-medias a {
        font-size: 2.2rem;
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {

    .footer-menu a,
    .footer-partner p,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 1.8rem;
    }

    .footer-partner {
        width: 19em;
    }

    .social-medias p,
    .social-medias a {
        font-size: 2.4rem;
    }
}

/*3200-1800*/
@media(min-width: 3200px) {
    .footer-partner {
        width: 20em;
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {

    .footer-menu a,
    .footer-partner p,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 2rem;
    }

    .footer-partner {
        width: 21em;
    }

    .social-medias p,
    .social-medias a {
        font-size: 2.6rem;
    }
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px) {

    .footer-menu a,
    .footer-partner p,
    .footer-bottom p,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 2.2rem;
    }

    .footer-partner {
        width: 23em;
    }

    .social-medias p,
    .social-medias a {
        font-size: 2.8rem;
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {}

/*1688-1080*/
@media(max-width: 1688px) {}

/*1680-1080*/
@media(max-width: 1680px) {}

/*1640-1080*/
@media(max-width: 1640px) {}

/* 1600 - 757 */
@media (max-width: 1600px) {

    .social-medias p,
    .social-medias a {
        font-size: 1.3rem;
    }

    .footer-bottom-list a {
        margin-right: 3em;
    }
}

/*1536-864*/
@media(max-width:1536px) {}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .footer-top {
        padding: 5em 7em;
    }

    .social-medias p,
    .social-medias a {
        font-size: 1.2rem;
    }
}

/*1366-638*/
@media (max-width:1366px) {
    .footer-bottom-list a {
        margin-right: 2.8em;
        letter-spacing: 1px;
    }
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
    .footer-top {
        padding: 5em 6em;
    }

    .footer-partner {
        width: 10em;
    }

    .footer-partner {
        margin-right: 4em;
    }

    .footer-bottom {
        padding: 2.5em 6em;
    }
}

/* 1200-1920 */
@media (max-width:1200px) {
    .footer-top {
        padding: 5em;
    }

    .footer-partner {
        width: 9.5em;
    }

    .footer-partner {
        margin-right: 3.5em;
    }

    .footer-bottom {
        padding: 2.5em 5em;
    }
}

/*1134x712*/
@media(max-width:1134px) {
    .footer-bottom-list a {
        margin-right: 2.5em;
    }

    .footer-partner {
        margin-right: 3em;
    }
}

/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
    .footer-top {
        padding: 4em;
    }

    .footer-menu a,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 0.95rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 1.1rem;
    }

    .footer-partner {
        width: 9.2em;
    }

    .footer-partner p {
        font-size: 0.85rem;
    }

    .footer-bottom {
        padding: 2em 4em;
    }
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
    .footer-partner {
        width: 9em;
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {

    .footer-menu a,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 0.9rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 1rem;
    }

    .footer-partner {
        width: 8.8em;
    }

    .footer-partner p {
        font-size: 0.8rem;
    }
}

/* 962x601 */
@media(max-width: 962px) {
    .footer-top {
        padding: 4 3.5em;
    }

    .footer-partner {
        width: 8.6em;
    }

    .footer-bottom {
        padding: 2em 3.5em;
    }
}

/* 900x1600 */
@media(max-width: 900px) {

    .footer-menu a,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 0.85rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 0.95rem;
    }

    .footer-partner {
        width: 8.4em;
    }
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {

    .footer-top {
        padding: 3.5em 3em;
    }

    .footer-partner {
        width: 8.2em;
    }

    .footer-bottom {
        padding: 1.8em 3em;
    }
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
    .footer-partner {
        width: 8em;
    }

    .footer-partner p {
        font-size: 0.75rem;
    }
}

/*712-1138*/
@media(max-width:712px) {
    .footer-top {
        padding: 3em 2.8em;
    }

    .footer-bottom {
        padding: 1.5em 2.8em;
    }
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
    .footer-top {
        padding: 2.5em;
    }

    .footer-menu a,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 0.8rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 0.9rem;
    }

    .footer-partner {
        width: 7.8em;
    }

    .footer-bottom {
        padding: 1.5em 2.5em;
    }
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
    .footer-partner {
        margin-right: 2.5em;
        width: 7.6em;
    }
}

/* 600x962 */
@media(max-width:600px) {
    .footer-top {
        padding: 2em;
    }

    .footer-partner {
        margin-right: 1.5em;
        width: 7.2em;
    }

    .footer-partner p {
        font-size: 0.7rem;
    }

    .footer-bottom {
        padding: 1.2em 2em;
    }
}

/* 577-951 */
@media(max-width:577px) {

    .footer-menu a,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 0.75rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 0.85rem;
    }

    .footer-partner {
        margin-right: 1.3em;
        width: 7em;
    }

    .footer-partner p {
        font-size: 0.65rem;
    }
}

/* 540-960 */
@media(max-width:540px) {

    .footer-menu a,
    .footer-bottom p,
    .footer-bottom-list a {
        font-size: 0.7rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 0.8rem;
    }

    .footer-partner {
        margin-right: 1em;
        width: 6.5em;
    }

    .footer-partner p {
        font-size: 0.6rem;
    }
}

/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
    .footer-top {
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 5em 7.5%;
    }

    .footer-menu {
        flex-direction: initial;
        flex-wrap: wrap;
        margin-bottom: 4em;
        justify-content: space-around
    }

    .footer-menu a {
        font-size: 1.2rem;
    }

    .footer-menu a:first-child {
        display: none;
    }

    .adits-mob-menu {
        width: 45%;
        margin: 0 auto;
        margin-bottom: 2em;
    }

    .adits-mob-menu img {
        width: 100%;
    }

    .footer-top-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
    }

    .footer-partner {
        margin-right: 0;
        margin-bottom: 3.8em;
        width: 13em;
    }

    .footer-partner p {
        font-size: 1.1rem;
    }

    .social-medias {
        text-align: center;
    }

    .social-medias p,
    .social-medias a {
        font-size: 1.5rem;
    }

    .footer-bottom {
        padding: 3.75em 0;
        width: 85%;
        margin: 0 auto;
        flex-direction: column-reverse;
        align-items: center
    }

    .footer-bottom-list {
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.25em;
    }

    .footer-bottom-list a,
    .footer-bottom p {
        margin-right: 0;
        font-size: 1.3rem;
    }

    .footer-bottom-list a:first-child {
        margin-bottom: 1.25em;
    }
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
    .footer-menu a {
        font-size: 1.15rem;
    }

    .footer-partner {
        width: 12em;
    }

    .footer-partner p {
        font-size: 1.05rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 1.4rem;
    }

    .footer-bottom-list a,
    .footer-bottom p {
        font-size: 1.25rem;
    }
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px) {
    .footer-menu a {
        font-size: 1.1rem;
    }

    .footer-partner {
        width: 11em;
    }

    .footer-partner p {
        font-size: 1rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 1.35rem;
    }

    .footer-bottom-list a,
    .footer-bottom p {
        font-size: 1.2rem;
    }
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px) {}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
    .adits-mob-menu {
        width: 48%;
    }

    .footer-menu a {
        font-size: 1.05rem;
    }

    .footer-partner {
        width: 10em;
    }

    .footer-partner p {
        font-size: 0.95rem;
    }

    .social-medias p,
    .social-medias a {
        font-size: 1.3rem;
    }

    .footer-bottom-list a,
    .footer-bottom p {
        font-size: 1.1rem;
    }
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) {}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
    .footer-menu a {
        font-size: 1rem;
    }

    .footer-bottom-list a,
    .footer-bottom p {
        font-size: 1.1rem;
    }
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {}

@media(max-width:380px) {}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {}

@media(max-width:369px) {}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
    .adits-mob-menu {
        width: 50%;
    }
}

@media(max-width:340px) {}

/* ip SE */
/*320x568*/
@media(max-width:320px) {}
</style>