<template>
<header v-if="$route.meta.header === true || $route.meta.jobHeader === true">
    <div class="adits-banner-header" v-if="$route.meta.header === true">
        <p class="menu-btn cursor-hover-effect">
            <span @click="menuOpened = !menuOpened" class="menu-btn-chars">
                <span>•</span>
                <span class="char" data-char="m" style="--char-index: 0">m</span>
                <span class="char" data-char="e" style="--char-index: 1">e</span>
                <span class="char" data-char="n" style="--char-index: 2">n</span>
                <span class="char" data-char="u" style="--char-index: 3">u</span>
            </span>
        </p>

        <router-link v-if="$route.meta.header === true" to="/adits" @click="menuOpened = false" class="adits-menu-logo cursor-hover-effect">
            <img alt="adits" src="../assets/home/adits-logo-white.svg">
        </router-link>
    </div>

    <div class="jobs-header" v-if="$route.meta.header === false && $route.meta.jobHeader === true">
        <div @click="jobsUnderlayTransition" class="jobs-header-logo cursor-hover-effect" id="jobsAditsImg">
            <p>Jobs by</p>
            <img alt="adits" src="../assets/home/adits-logo-white.svg">
        </div>

        <div @click="jobsUnderlayTransition" class="back-btn cursor-hover-effect" id="projekteBackBtn">
            <a class="go-back"></a>
            <p v-if="!tablet">Back</p>
        </div>
    </div>

    <transition name="navMenu" v-if="$route.meta.header === true">
        <nav v-if="menuOpened" class="adits-menu">
            <transition name="navmMenuHeader" appear>
                <div class="adits-menu-header">
                    <p class="menu-btn" id="menu-btn-opened-menu">
                        <span @click="menuOpened = !menuOpened" class="menu-btn-chars cursor-hover-effect">
                            <span>•</span>
                            <span class="char" data-char="c" style="--char-index: 4">c</span>
                            <span class="char" data-char="l" style="--char-index: 5">l</span>
                            <span class="char" data-char="o" style="--char-index: 6">o</span>
                            <span class="char" data-char="s" style="--char-index: 7">s</span>
                            <span class="char" data-char="e" style="--char-index: 8">e</span>
                        </span>
                    </p>

                    <router-link @click="menuOpened = false" to="/adits" class="adits-menu-logo cursor-hover-effect" id="adits-menu-logo-opened">
                        <img src="../assets/home/adits-logo-white.svg">
                    </router-link>
                </div>
            </transition>

            <div class="adits-menu-content">
                <transition name="navMenuList " appear>
                    <div class="adits-menu-list">
                        <router-link to="/adits" @click="closeMenu()" class="adits-menu-list-item cursor-hover-effect">
                            <span class="char" data-char="A" style="--char-index: 0">A</span>
                            <span class="char" data-char="D" style="--char-index: 1">D</span>
                            <span class="char" data-char="I" style="--char-index: 2">I</span>
                            <span class="char" data-char="T" style="--char-index: 3">T</span>
                            <span class="char" data-char="S" style="--char-index: 4">S</span>
                        </router-link>
                        <router-link to="/dienstleistungen/0" @click="closeMenu()" class="adits-menu-list-item cursor-hover-effect">
                            <span class="char" data-char="L" style="--char-index: 0">L</span>
                            <span class="char" data-char="E" style="--char-index: 1">E</span>
                            <span class="char" data-char="I" style="--char-index: 2">I</span>
                            <span class="char" data-char="S" style="--char-index: 3">S</span>
                            <span class="char" data-char="T" style="--char-index: 4">T</span>
                            <span class="char" data-char="U" style="--char-index: 5">U</span>
                            <span class="char" data-char="N" style="--char-index: 6">N</span>
                            <span class="char" data-char="G" style="--char-index: 7">G</span>
                            <span class="char" data-char="E" style="--char-index: 8">E</span>
                            <span class="char" data-char="N" style="--char-index: 9">N</span>
                        </router-link>
                        <router-link to="/projekte" @click="closeMenu()" class="adits-menu-list-item cursor-hover-effect">
                            <span class="char" data-char="P" style="--char-index: 0">P</span>
                            <span class="char" data-char="R" style="--char-index: 1">R</span>
                            <span class="char" data-char="O" style="--char-index: 2">O</span>
                            <span class="char" data-char="J" style="--char-index: 3">J</span>
                            <span class="char" data-char="E" style="--char-index: 4">E</span>
                            <span class="char" data-char="K" style="--char-index: 5">K</span>
                            <span class="char" data-char="T" style="--char-index: 6">T</span>
                            <span class="char" data-char="E" style="--char-index: 7">E</span>
                        </router-link>
                        <router-link to="/#expertise" @click="closeMenu()" class="adits-menu-list-item cursor-hover-effect">
                            <span class="char" data-char="E" style="--char-index: 0">E</span>
                            <span class="char" data-char="X" style="--char-index: 1">X</span>
                            <span class="char" data-char="P" style="--char-index: 2">P</span>
                            <span class="char" data-char="E" style="--char-index: 3">E</span>
                            <span class="char" data-char="R" style="--char-index: 4">R</span>
                            <span class="char" data-char="T" style="--char-index: 5">T</span>
                            <span class="char" data-char="I" style="--char-index: 6">I</span>
                            <span class="char" data-char="S" style="--char-index: 7">S</span>
                            <span class="char" data-char="E" style="--char-index: 8">E</span>
                        </router-link>
                        <router-link to="/team" @click="closeMenu()" class="adits-menu-list-item cursor-hover-effect">
                            <span class="char" data-char="T" style="--char-index: 0">T</span>
                            <span class="char" data-char="E" style="--char-index: 1">E</span>
                            <span class="char" data-char="A" style="--char-index: 2">A</span>
                            <span class="char" data-char="M" style="--char-index: 3">M</span>
                        </router-link>
                        <router-link to="/aboutUs" @click="closeMenu()" class="adits-menu-list-item cursor-hover-effect">
                            <span class="char" data-char="Ü" style="--char-index: 0">Ü</span>
                            <span class="char" data-char="B" style="--char-index: 1">B</span>
                            <span class="char" data-char="E" style="--char-index: 2">E</span>
                            <span class="char" data-char="R" style="--char-index: 3; margin-right: 5px;">R</span>
                            <span class="char" data-char="U" style="--char-index: 5"> U</span>
                            <span class="char" data-char="N" style="--char-index: 6">N</span>
                            <span class="char" data-char="S" style="--char-index: 7">S</span>
                        </router-link>
                        <router-link to="/contact" @click="closeMenu()" class="adits-menu-list-item cursor-hover-effect">
                            <span class="char" data-char="K" style="--char-index: 0">K</span>
                            <span class="char" data-char="O" style="--char-index: 1">O</span>
                            <span class="char" data-char="N" style="--char-index: 2">N</span>
                            <span class="char" data-char="T" style="--char-index: 3">T</span>
                            <span class="char" data-char="A" style="--char-index: 4">A</span>
                            <span class="char" data-char="K" style="--char-index: 5">K</span>
                            <span class="char" data-char="T" style="--char-index: 6">T</span>
                        </router-link>
                        <router-link to="/jobs" @click="closeMenu()" class="adits-menu-list-item jobs cursor-hover-effect">
                            <span class="char" data-char="J" style="--char-index: 0">J</span>
                            <span class="char" data-char="O" style="--char-index: 1">O</span>
                            <span class="char" data-char="B" style="--char-index: 2">B</span>
                            <span class="char" data-char="S" style="--char-index: 3">S</span>
                        </router-link>
                    </div>
                </transition>
                <transition name="navMenuQuote" appear>
                    <div class="menu-quote">
                        <p>GEMEINSAM STARTEN</p>
                    </div>
                </transition>
            </div>
        </nav>
    </transition>

    <transition name="navMenuUnderlay" v-if="$route.meta.header === true">
        <div v-if="menuOpened" class="underlay"></div>
    </transition>

    <transition name="navMenuUnderlay" v-if="$route.meta.header === false">
        <div v-if="jobsUnderlay" class="underlay-jobs">
            <div class="underlay-jobs-elements">
                <p>Jobs by</p>
                <img alt="adits" src="../assets/home/adits-logo-white.svg">
            </div>
        </div>
    </transition>

    <transition name="navMenuUnderlay" v-if="$route.meta.isProjekte === true && !mob">
        <div v-if="projekteUnderlay" class="underlay-projekte">
            <div class="underlay-projekte-elements">
                <img alt="adits" src="../assets/home/adits-logo-white.svg">
            </div>
        </div>
    </transition>
</header>

<div v-if="$route.meta.isProjekte === true && !mob" @click="projekteUnderlayTransition" class="back-btn-projekte cursor-hover-effect" id="projekteBackBtn">
    <a class="go-back-projekte"></a>
    <p v-if="!tablet">Back</p>
</div>
</template>

<script>
import {
    ref
} from 'vue'
import {
    useRouter
} from 'vue-router';
export default {
    watch: {
        menuOpened(newValue) {
            const body = document.querySelector('body')
            if (newValue == true) {
                body.style.overflow = "hidden"
                this.$nextTick(() => {
                    this.selectCursorHoverElements();
                });
            } else {
                setTimeout(() => {
                    body.style.overflow = "initial"
                }, 300);
            }
        },
    },
    setup() {
        const router = useRouter();

        var menuOpened = ref(false)

        var projekteUnderlay = ref(false)

        var jobsUnderlay = ref(false)

        var tablet = ref(false)
        var mob = ref(false)

        function windowResize() {
            if (window.innerWidth <= 1114) {
                tablet.value = true
            } else {
                tablet.value = false
            }
            if (window.innerWidth <= 438) {
                mob.value = true
            } else {
                mob.value = false
            }
        }

        function closeMenu() {
            menuOpened.value = !menuOpened.value
        }

        function jobsUnderlayTransition() {
            jobsUnderlay.value = true
            setTimeout(() => {
                jobsUnderlay.value = false;
                router.push('/adits');
            }, 1200)
        }

        function projekteUnderlayTransition() {
            projekteUnderlay.value = true
            setTimeout(() => {
                projekteUnderlay.value = false;
                router.push('/');
            }, 1200)
        }

        return {
            menuOpened,
            closeMenu,
            projekteUnderlay,
            jobsUnderlay,
            jobsUnderlayTransition,
            projekteUnderlayTransition,
            windowResize,
            tablet,
            mob
        }
    },

    methods: {
        selectCursorHoverElements() {
            let innerCursor = document.querySelector(".cursor");
            let links = Array.from(document.querySelectorAll(".cursor-hover-effect"));

            links.forEach((link) => {
                link.addEventListener("mouseover", () => {
                    innerCursor.classList.add("grow");
                });
                link.addEventListener("mouseleave", () => {
                    innerCursor.classList.remove("grow");
                });
            });
        },
    },
    mounted() {
        this.windowResize()
        window.addEventListener("resize", this.windowResize);

        this.$nextTick(() => {
            this.selectCursorHoverElements();
        });
    },
    unmounted() {
        window.removeEventListener("resize", this.windowResize);
    },
}
</script>

<style scoped>
/* back btn */
.back-btn-projekte {
    position: fixed;
    top: 15%;
    right: 0;
    display: flex;
    align-items: center;
    transition: 0.5s ease;
    background-color: black;
    float: right;
}

.back-btn-projekte:hover {
    right: 1%;
}

.go-back-projekte {
    position: relative;
    font-size: 5rem;
}

.go-back-projekte::before,
.go-back-projekte::after {
    content: "";
    display: block;
    left: 0px;
    position: absolute;
    top: 50%;
    transition: left 0.1s;
}

.go-back-projekte::before {
    border-color: #ED4815;
    border-style: solid;
    border-width: 0 0 0.0625em 0.0625em;
    height: 0.375em;
    margin-top: -0.1875em;
    width: 0.375em;
    transform: rotate(45deg);
}

.go-back-projekte::after {
    background: #ED4815;
    height: 0.0625em;
    width: 0.75em;
    transition: 0.3s ease;
}

.back-btn-projekte:hover .go-back-projekte::after {
    width: 1.1em;
}

.back-btn-projekte p {
    color: #ED481B;
    font-size: 2.6rem;
    transform: scaleX(-1);
    font-weight: bold;
    margin-left: 86px;
    transition: 0.5s ease;
    opacity: 0;
}

.back-btn-projekte:hover p {
    opacity: 1;
}

.test {
    font-size: 4rem;
    position: fixed;
    top: 15%;
    left: 0;
    z-index: 800;
}

/* jobs header */
.jobs-header {
    justify-content: space-between;
    align-items: center;
    position: absolute;
    display: flex;
    width: 85%;
    padding: 0 7.5%;
    height: 10vh;
    background-color: #696BFB;
}

.jobs-header-logo {
    width: 12%
}

.jobs-header-logo img {
    width: 100%;
}

.jobs-header-logo p {
    font-size: 0.9rem;
}

.back-btn {
    display: flex;
    align-items: center;
    transition: 0.5s ease;
    position: relative;
}

.go-back {
    position: relative;
    font-size: 4.5rem;
}

.go-back::before,
.go-back::after {
    content: "";
    display: block;
    left: 0px;
    position: absolute;
    top: 50%;
    transition: left 0.3s;
}

.go-back::before {
    border-color: white;
    border-style: solid;
    border-width: 0 0 0.0625em 0.0625em;
    height: 0.375em;
    margin-top: -0.1875em;
    width: 0.375em;
    transform: rotate(45deg);
}

.go-back::after {
    background: white;
    height: 0.0625em;
    width: 0.75em;
    transition: 0.3s ease;
}

.back-btn:hover .go-back::after {
    width: 1.45em;
}

.back-btn:hover .go-back::before,
.back-btn:hover .go-back::after {
    left: -0.14em;
}

.back-btn p {
    color: white;
    font-size: 2.6rem;
    transform: scaleX(-1);
    font-weight: bold;
    margin-left: 86px;
    transition: 0.5s ease;
    opacity: 0;
}

.back-btn:hover p {
    opacity: 1;
}

.underlay-jobs {
    background-color: #696BFB !important;
}

.underlay-projekte {
    background-color: black !important;
}

.underlay-projekte-elements {
    width: 22%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.underlay-projekte-elements img {
    width: 100%;
}

.underlay-jobs-elements {
    width: 22%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.underlay-jobs-elements p {
    font-size: 2rem;
}

.underlay-jobs-elements img {
    width: 100%;
}

/* default header */
header {
    background-color: black;
    width: 100%;
    height: 10vh;
    position: relative;
}

.adits-menu {
    position: fixed;
    height: 100vh;
    z-index: 9;
    width: 90%;
    padding: 0 5%;
    z-index: 1000;
    background-color: black;
}

.adits-menu-content {
    display: flex;
    justify-content: space-between;
    height: 90vh;
}

.adits-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 9vh;
    padding-top: 1vh;
}

#menu-btn-opened-menu,
#adits-menu-logo-opened {
    position: relative !important;
    right: 0 !important;
    left: 0 !important;
}

#adits-menu-logo-opened {
    align-self: center;
}

.adits-menu-list {
    padding-top: 50px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
}

.adits-menu-list-item {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 0.6em;
    padding-left: 0.4em;
    line-height: 1.5;
}

.adits-menu-list .blog {
    background-color: white;
    color: #ED4815;
}

.adits-menu-list .jobs {
    background-color: #696BFB;
    color: white;
}

.menu-quote {
    text-align: center;
    padding: 0 2.5em;
    height: 100%;
    background-color: #ED4815;
    display: flex;
}

.menu-quote p {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

/* nav menu */
.navMenu-enter-active,
.navMenu-leave-active {
    transition: 1.8s ease all;
}

/* nav quote */
.navMenuQuote-enter-active,
.navMenuQuote-leave-active {
    transition: 1.4s ease all;
}

/* nav underlay */
.navMenuUnderlay-enter-active {
    transition: 1.2s ease all;
}

.navMenuUnderlay-leave-active {
    transition: 2.2s ease all;
    transition: 3s ease all;
}

/* nav menu quote underlay */
.underlay,
.underlay-jobs,
.underlay-projekte {
    height: 100vh;
    width: 100%;
    background-color: #ED4815;
    position: fixed;
    z-index: 999;
}

.navMenu-enter-from,
.navMenuQuote-enter-from,
.navMenuUnderlay-enter-from {
    transform: translateY(+100%);
}

.navMenu-leave-to,
.navMenuQuote-leave-to,
.navMenuUnderlay-leave-to {
    transform: translateY(-100%);
}

.navMenu-enter-to,
.navMenuQuote-enter-to,
.navMenuUnderlay-enter-to {
    transform: translateY(0);
}

/* nav list */
.navMenuList-enter-active {
    transition: 1.2s ease all;
}

.navMenuList-leave-active {
    transition: 2.2s ease all;
}

.navMenuList-enter-from {
    transform: translateY(+100%);
}

.navMenuList-leave-to {
    transform: translateY(-100%);
}

.navMenuList-enter-to {
    transform: translateY(0);
}

/* nav header */
.navmMenuHeader-enter-active {
    transition: 1.2s ease all;
}

.navmMenuHeader-leave-active {
    transition: 2.2s ease all;
}

.navmMenuHeader-enter-from {
    transform: translateY(+100%);
}

.navmMenuHeader-leave-to {
    transform: translateY(-100%);
}

.navmMenuHeader-enter-to {
    transform: translateY(0);
}

.menu-btn {
    font-size: 1.5rem;
    color: white;
    z-index: 4;
    font-weight: bold;
    position: fixed;
    padding-left: 5%;
    padding-right: 1%;
    margin-left: -5%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
}

.menu-btn-chars,
.adits-menu-list-item {
    overflow: hidden;
    display: block;
}

.menu-btn-chars .char,
.adits-menu-list-item .char {
    display: inline-block;
    position: relative;
    transition: transform 0.4s calc(var(--char-index) * 40ms) ease-in-out;
}

.menu-btn-chars .char::before,
.adits-menu-list-item .char::before {
    content: attr(data-char);
    transform: rotate(-20.001deg);
    transform-origin: left bottom;
    position: absolute;
    transition: transform 0.4s calc(var(--char-index) * 40ms) ease-in-out;
    top: 100%;
    left: 0;
}

.menu-btn-chars:hover .char,
.adits-menu-list-item:hover .char {
    transform: translateY(-100%);
}

.menu-btn-chars:hover .char::before,
.adits-menu-list-item:hover .char::before {
    transform: translateY(0);
}

.adits-menu-logo {
    z-index: 4;
    width: 10%;
    display: block;
    align-self: flex-end;
}

.adits-banner-header {
    position: absolute;
    z-index: 4;
    width: 90%;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 9vh;
    padding-top: 1vh;
}

.adits-menu-logo img {
    width: 100%;
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
    .back-btn-projekte p {
        font-size: 2.8rem;
    }

    .jobs-header-logo p {
        font-size: 1rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.8rem;
    }

    .adits-menu-logo,
    #adits-menu-logo-opened {
        width: 12%;
    }

    .menu-quote p {
        font-size: 2.5rem;
    }
}

/* 2304-1470 */
@media (min-width: 2304px) {
    .underlay-projekte-elements {
        width: 24%;
    }

    .back-btn-projekte p {
        font-size: 3rem;
    }

    .go-back-projekte {
        font-size: 5.5rem;
    }

    /* jobs */
    .go-back {
        font-size: 5rem;
    }

    .back-btn:hover .go-back::after {
        width: 1.35em;
    }

    .back-btn p {
        font-size: 2.8rem;
    }

    .underlay-jobs-elements {
        width: 24%;
    }

    .underlay-jobs-elements p {
        font-size: 2.5rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 2rem;
    }

    .menu-quote p {
        font-size: 2.7rem;
    }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
    .underlay-projekte-elements {
        width: 26%;
    }

    .back-btn-projekte p {
        font-size: 3.5rem;
    }

    .go-back-projekte {
        font-size: 5.8rem;
    }

    /* jobs */
    .jobs-header-logo p {
        font-size: 1.2rem;
    }

    .go-back {
        font-size: 5.5rem;
    }

    .back-btn:hover .go-back::after {
        width: 1.3em;
    }

    .back-btn p {
        font-size: 3rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 2.2rem;
    }

    .menu-quote p {
        font-size: 3rem;
    }
}

/*2573-1206*/
@media(min-width: 2573px) {}

/* 2880-1670 */
@media(min-width: 2880px) {
    .underlay-projekte-elements {
        width: 28%;
    }

    .back-btn-projekte p {
        font-size: 4rem;
    }

    .go-back-projekte {
        font-size: 6rem;
    }

    /* jobs */
    .jobs-header-logo p {
        font-size: 1.5rem;
    }

    .go-back {
        font-size: 6.5rem;
    }

    .back-btn:hover .go-back::after {
        width: 1.1em;
    }

    .back-btn p {
        font-size: 3.5rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 2.4rem;
    }

    .menu-quote p {
        font-size: 3.2rem;
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
    .underlay-projekte-elements {
        width: 30%;
    }

    .go-back-projekte {
        font-size: 6.5rem;
    }

    .underlay-jobs-elements p {
        font-size: 3rem;
    }

    .jobs-header-logo {
        width: 14%;
    }

    .jobs-header-logo p {
        font-size: 1.8rem;
    }

    .go-back {
        font-size: 7rem;
    }

    .back-btn:hover .go-back::after {
        width: 1em;
    }

    .back-btn p {
        font-size: 3.8rem;
    }
}

/*3200-1800*/
@media(min-width: 3200px) {

    .menu-btn,
    .adits-menu-list-item {
        font-size: 2.6rem;
    }

    .menu-quote p {
        font-size: 3.4rem;
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {
    .underlay-projekte-elements {
        width: 32%;
    }

    .go-back-projekte {
        font-size: 7rem;
    }

    .back-btn-projekte p {
        font-size: 4.5rem;
    }

    .back-btn-projekte:hover .go-back-projekte::after {
        width: 1em;
    }

    .underlay-jobs-elements p {
        font-size: 3.5rem;
    }

    .jobs-header-logo p {
        font-size: 2rem;
    }

    .go-back {
        font-size: 8rem;
    }

    .back-btn:hover .go-back::after {
        width: 0.9em;
    }

    .back-btn p {
        font-size: 4rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 2.8rem;
    }

    .menu-quote p {
        font-size: 3.6rem;
    }
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px) {
    .back-btn-projekte p {
        font-size: 5.5rem;
    }

    .go-back-projekte {
        font-size: 7.5rem;
    }

    .underlay-jobs-elements p {
        font-size: 4rem;
    }

    .jobs-header-logo p {
        font-size: 2.2rem;
    }

    .go-back {
        font-size: 9rem;
    }

    .back-btn p {
        font-size: 4.5rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 3rem;
    }

    .menu-quote p {
        font-size: 3.8rem;
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
    .underlay-projekte-elements {
        width: 24%;
    }

    .back-btn-projekte p {
        margin-left: 65px;
    }

    .go-back-projekte {
        font-size: 4.8rem;
    }

    .underlay-jobs-elements p {
        font-size: 1.8rem;
    }

    .jobs-header-logo {
        width: 13%;
    }

    .jobs-header-logo p {
        font-size: 1rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.6rem;
    }

    .menu-quote p {
        font-size: 1.8rem;
    }
}

/*1688-1080*/
@media(max-width: 1688px) {
    .go-back-projekte {
        font-size: 4.4rem;
    }

    .back-btn-projekte p {
        margin-left: 61px;
    }
}

/*1680-1080*/
@media(max-width: 1680px) {
    .go-back {
        font-size: 4rem;
    }

    .back-btn:hover .go-back::after {
        width: 1.7em;
    }
}

/*1640-1080*/
@media(max-width: 1640px) {
    .underlay-jobs-elements p {
        font-size: 1.7rem;
    }
}

/* 1600 - 757 */
@media (max-width: 1600px) {
    .underlay-projekte-elements {
        width: 26%;
    }

    .jobs-header-logo {
        width: 11%;
    }

    .jobs-header-logo p {
        font-size: 0.9rem;
    }

    .go-back {
        font-size: 3.8rem;
    }

    .back-btn p {
        font-size: 2.4rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.5rem;
    }
}

/*1536-864*/
@media(max-width:1536px) {
    .back-btn-projekte:hover {
        right: 0.5%;
    }

    .back-btn-projekte p {
        margin-left: 68px;
        font-size: 2.4rem;
    }

    .go-back-projekte::before,
    .go-back-projekte::after {
        left: 0.1em;
    }

    .underlay-jobs-elements p {
        font-size: 1.5rem;
    }
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .underlay-projekte-elements {
        width: 28%;
    }

    .go-back-projekte {
        font-size: 3.5rem;
    }

    .back-btn-projekte p {
        font-size: 2.2rem;
        margin-left: 58px;
    }

    .go-back {
        font-size: 3.5rem;
    }

    .back-btn:hover .go-back::after {
        width: 1.8em;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.4rem;
    }

    .adits-menu-logo {
        width: 11%;
    }

    .menu-quote p {
        font-size: 1.7rem;
    }

    .menu-quote {
        padding: 0 2.2em;
    }
}

/*1366-638*/
@media (max-width:1366px) {
    .underlay-jobs-elements p {
        font-size: 1.4rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.3rem;
    }

    .menu-quote {
        padding: 0 2em;
    }
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
    .go-back-projekte {
        font-size: 3rem;
    }

    .back-btn-projekte p {
        margin-left: 44px;
    }

    .underlay-jobs-elements {
        width: 24%;
    }

    .jobs-header-logo p {
        font-size: 0.8rem;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.2rem;
    }

    .menu-quote {
        padding: 0 1.8em;
    }
}

/* 1200-1920 */
@media (max-width:1200px) {
    .back-btn-projekte p {
        font-size: 2rem;
    }

    .go-back-projekte {
        font-size: 2.8rem;
    }

    .go-back {
        font-size: 3.3rem;
    }

    .back-btn p {
        font-size: 2.2rem;
    }

    .back-btn:hover .go-back::after {
        width: 1.9em;
    }

    .back-btn:hover .go-back::before,
    .back-btn:hover .go-back::after {
        left: -0.1em;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.1rem;
    }

    .menu-quote p {
        font-size: 1.4rem;
    }

    .menu-quote {
        padding: 0 1.6em;
    }
}

/*1134x712*/
@media(max-width:1134px) {
    .back-btn-projekte p {
        margin-left: 40px;
    }

    .underlay-jobs-elements {
        width: 26%;
    }

    .back-btn p {
        margin-left: 60px;
    }

    .back-btn:hover .go-back::after {
        width: 1.4em;
    }

    .back-btn p {
        font-size: 2rem;
    }

    .menu-quote {
        padding: 0 1.4em;
    }
}

/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
    .jobs-header-logo {
        width: 13%;
    }

    .back-btn-projekte,
    .back-btn {
        width: 4.5%;
    }

    .back-btn-projekte:hover .go-back-projekte::before,
    .back-btn-projekte:hover .go-back-projekte::after,
    .back-btn:hover .go-back::before,
    .back-btn:hover .go-back::after {
        left: 0;
    }

    .back-btn-projekte:hover .go-back-projekte::after,
    .back-btn:hover .go-back::after {
        width: 0.75em;
    }

    .back-btn-projekte,
    .back-btn-projekte:hover,
    .back-btn,
    .back-btn:hover {
        right: 1%;
    }

    .go-back-projekte {
        font-size: 3.2rem;
    }

    .back-btn p,
    .back-btn-projekte p {
        display: none;
    }

    .adits-menu-logo {
        width: 12%;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.3rem;
    }
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
    .underlay-projekte-elements {
        width: 40%;
    }

    .underlay-jobs-elements {
        width: 28%;
    }

    .jobs-header-logo {
        width: 15%;
    }

    .navMenuUnderlay-leave-active {
        transition: 2.4s ease all;
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
    .underlay-jobs-elements {
        width: 30%;
    }

    .jobs-header-logo {
        width: 16%;
    }

    .jobs-header-logo p {
        font-size: 0.9rem;
    }

    .adits-menu-logo {
        width: 16%;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.5rem;
    }

    .menu-quote p {
        font-size: 1.7rem;
    }
}

/* 962x601 */
@media(max-width: 962px) {
    .adits-menu-logo {
        width: 18%;
    }

    .adits-banner-header {
        height: 7vh;
        padding-top: 3vh;
    }

    .menu-btn,
    .adits-menu-list-item {
        font-size: 1.6rem;
    }

    .menu-quote p {
        font-size: 1.6rem;
    }
}

/* 900x1600 */
@media(max-width: 900px) {
    .back-btn-projekte {
        width: 5%;
    }
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
    .underlay-projekte-elements {
        width: 42%;
    }

    .underlay-jobs-elements {
        width: 34%;
    }
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
    .underlay-projekte-elements {
        width: 45%;
    }

    .back-btn-projekte {
        width: 5.5%;
    }

    header,
    .jobs-header {
        height: 12vh;
    }

    .jobs-header-logo {
        width: 25%;
    }

    .jobs-header-logo p {
        font-size: 1.1rem;
    }

    .back-btn {
        width: 7%;
    }

    .go-back {
        font-size: 4rem;
    }
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
    .underlay-jobs-elements {
        width: 36%;
    }
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
    .go-back-projekte {
        font-size: 3rem;
    }
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
    .back-btn-projekte {
        width: 6%;
    }

    .back-btn {
        width: 8.5%;
    }

    .go-back {
        font-size: 4.5rem;
    }

    .adits-banner-header {
        height: 9vh;
        padding-top: 1vh;
    }

    .adits-menu-logo {
        width: 20%;
    }
}

/*712-1138*/
@media(max-width:712px) {

    .underlay-projekte-elements {
        width: 50%;
    }

    .underlay-jobs-elements {
        width: 42%;
    }
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
    .back-btn {
        width: 9.5%;
    }

    .adits-menu-logo {
        width: 22%;
    }
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
    .adits-menu-list-item {
        font-size: 1.5rem;
    }

    .adits-menu-logo {
        width: 24%;
    }
}

/* 600x962 */
@media(max-width:600px) {
    .back-btn-projekte {
        width: 7%;
    }

    .underlay-jobs-elements {
        width: 50%;
    }

    .back-btn {
        width: 10.5%;
    }
}

/* 577-951 */
@media(max-width:577px) {
    .go-back-projekte {
        font-size: 2.8rem;
    }

    .back-btn {
        width: 10%;
    }

    .go-back {
        font-size: 4rem;
    }

    .adits-menu-list-item {
        font-size: 1.4rem;
    }

    .adits-menu-logo {
        width: 25%;
    }
}

/* 540-960 */
@media(max-width:540px) {
    .underlay-jobs-elements {
        width: 55%;
    }

    .jobs-header-logo {
        width: 28%;
    }

    .back-btn {
        width: 9.5%;
    }

    .go-back {
        font-size: 3.5rem;
    }
}

/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
    .underlay-jobs-elements {
        width: 60%;
    }

    .back-btn {
        width: 10%;
    }

    .adits-menu-logo {
        width: 26%;
    }

    .menu-btn {
        font-size: 1.5rem;
    }

    .adits-menu-list-item {
        font-size: 1.3rem;
    }

    .menu-quote p {
        font-size: 1.4rem;
    }

    .menu-btn {
        padding: 0.15em 0;
        padding-left: 5%;
        padding-right: 1%;
    }
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {

    header,
    .jobs-header {
        height: 10vh;
    }

    .jobs-header-logo {
        width: 32%;
    }

    .back-btn {
        width: 10.5%;
    }

    .jobs-header-logo p {
        font-size: 1rem;
    }

    .go-back {
        font-size: 3.3rem;
    }
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px) {
    .underlay-jobs-elements p {
        font-size: 1.2rem;
    }

    .adits-menu-logo {
        width: 28%;
    }

    .menu-btn {
        font-size: 1.4rem;
    }
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px) {}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
    .jobs-header-logo p {
        font-size: 0.9rem;
    }

    .back-btn {
        width: 11%;
    }

    .adits-menu-logo {
        width: 30%;
    }

    .adits-menu-list-item {
        font-size: 1.25rem;
    }

    .menu-quote p {
        font-size: 1.3rem;
    }
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) {}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {
    .underlay-jobs-elements {
        width: 65%;
    }

    .jobs-header-logo {
        width: 34%;
    }

    .back-btn {
        width: 11.5%;
    }
}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
    .jobs-header-logo {
        width: 36%;
    }

    .back-btn {
        width: 12%;
    }

    .menu-quote p {
        font-size: 1.2rem;
    }
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
    .go-back {
        font-size: 3rem;
    }

    .back-btn {
        width: 12%;
    }
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
    .underlay-jobs-elements p {
        font-size: 1.1rem;
    }
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
    .underlay-jobs-elements {
        width: 70%;
    }

    .back-btn {
        width: 13%;
    }
}
</style>