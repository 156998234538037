import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/comingsoon',
    name: 'comingsoon',
    component: () => import('../kml/KMediaLabs.vue'),
    meta:{
      header: false,
      jobHeader: false,
      footer: false,
      preloader: false,
      cursor: false,
    }
  },
  {
    path: '/',
    name: 'pick',
    component: () => import('../views/PickPage.vue'),
    meta:{
      // header: true,
      // footer: true,
      preloader: true,
      cursor: true
    }
  },
  {
    path: '/adits',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta:{
      header: true,
      footer: true,
      preloader: true,
      cursor: true
    }
  },
  {
    path: '/dienstleistungen/:id',
    name: 'dienstleistungen',
    component: () => import('../views/Dienstleistungen.vue'),
    meta:{
      header: true,
      footer: true,
      preloader: false,
      cursor: true
    }
  },
  {
    path: '/projekte',
    name: 'projekte',
    component: () => import('../views/Projekte.vue'),
    meta:{
      header: true,
      isProjekte: true,
      footer: true,
      preloader: false,
      cursor: true
    }
  },
  // {
  //   path: '/cookies',
  //   name: 'cookies',
  //   component: () => import('../components/Cookies.vue'),
  // },
  // {
  //   path: '/contact2',
  //   name: 'contact2',
  //   component: () => import('../components/Contact.vue'),
  // },
  // {
  //   path: '/form',
  //   name: 'form',
  //   component: () => import('../components/JobApplication.vue'),
  //   meta:{
  //     header: true,
      
  //     footer: true,
  //     preloader: false,
  //     cursor: false
  //   }
  // },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/Team.vue'),
    meta:{
      header: true,
      footer: true,
      preloader: false,
      cursor: true
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/AboutUs.vue'),
    meta:{
      header: true,
      footer: true,
      preloader: false,
      cursor: true
    }
  },
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: () => import('../views/Blog.vue')
  // },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('../views/Jobs.vue'),
    meta:{
      jobHeader: true,
      header: false,
      footer: false,
      preloader: false,
      cursor: true
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue'),
    meta:{
      header: true,
      footer: true,
      preloader: false,
      cursor: true
    }
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import('../views/Impressum.vue'),
    meta:{
      header: true,
      footer: true,
      preloader: false,
      cursor: true
    }
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: () => import('../views/Datenschutz.vue'),
    meta:{
      header: true,
      footer: true,
      preloader: false,
      cursor: true
    }
  },
  {
    path: '/memberDetails/:id',
    name: 'memberDetails',
    component:() => import('../components/MemberDetails.vue'),
    meta:{
      requiresHeader: true,
      requiresFooter: true,
      header: true,
      footer: true,
      preloader: false,
      cursor: true
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'badRoute',
    component:() => import('../views/BadRoute.vue'),
    meta:{
      header: true,
      footer: true,
      preloader: false,
      cursor: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   return savedPosition || {top:0}
  // }
  // scrollBehavior(to) {
  //   if (to.hash) {
  //     return { el: to.hash };
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    } else {
      return  savedPosition || {top:0} ;
    }
  },
})


export default router
